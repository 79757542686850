
	import { Vue, Component, Prop } from 'vue-property-decorator';
	import { mapState, mapGetters } from 'vuex';
	import {
		NAMESPACE as USER_NAMESPACE,
		GetterTypes as UserGettersTypes,
		Getters as UserGetters,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import Avatar from '@pixcap/ui-core/components/common/Avatar.vue';
	@Component({
		name: 'UserInfo',
		components: {
			Avatar,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
			}),
			...mapGetters(USER_NAMESPACE, {
				subscriptionText: UserGettersTypes.SUBSCRIPTION_TEXT,
			}),
		},
	})
	export default class UserInfo extends Vue {
		@Prop() readonly variant: undefined | 'lg';
		personalData: UserState['personalData'];
		subscriptionText: ReturnType<UserGetters['subscriptionText']>;
	}
