
	import { Component, Vue } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { mutationsWrapper } from '@pixcap/ui-core/store/user';
	import { NAMESPACE as USER_NAMESPACE, UserState, MY_ACCOUNT_TAB } from '@pixcap/ui-core/models/store/user.interface';

	import AccountSettingForm from '@pixcap/ui-core/components/account/form/AccountSettingForm.vue';
	import ChangePasswordForm from '@pixcap/ui-core/components/account/form/ChangePasswordForm.vue';
	import Subscription from '@pixcap/ui-core/components/account/Subscription.vue';
	import AccountNavLink from '@pixcap/ui-core/components/account/common/AccountNavLink.vue';
	import EmailNotifications from '@pixcap/ui-core/components/account/form/EmailNotifications.vue';

	import Modal from '@pixcap/ui-library/components/Modal.vue';
	import { TEXT_TABS_SHOW } from '@pixcap/ui-core/constants/user.constants';
	import { QUERY_SETTINGS, QUERY_SETTINGS_EMAIL_NOTIFICATIONS } from '@pixcap/ui-core/constants/shared.constants';

	@Component({
		name: 'MyAccount',
		components: {
			AccountSettingForm,
			ChangePasswordForm,
			Subscription,
			Modal,
			AccountNavLink,
			EmailNotifications,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
				activeMyAccountTab: (state: UserState) => state.activeMyAccountTab,
				emailNotifications: (state: UserState) => state.emailNotifications,
			}),
		},
	})
	export default class MyAccount extends Vue {
		MY_ACCOUNT_TAB = MY_ACCOUNT_TAB;
		TEXT_TABS_SHOW = TEXT_TABS_SHOW;

		personalData: UserState['personalData'];
		activeMyAccountTab: UserState['activeMyAccountTab'];
		emailNotifications: UserState['emailNotifications'];

		get tabs() {
			const tabs = [MY_ACCOUNT_TAB.ACCOUNT_SETTINGS, MY_ACCOUNT_TAB.SUBSCRIPTION, MY_ACCOUNT_TAB.CHANGE_PASSWORD, MY_ACCOUNT_TAB.EMAIL_NOTIFICATIONS];
			if (!this.personalData?.user?.isFederated) return tabs;
			return tabs.filter((tab) => tab !== MY_ACCOUNT_TAB.CHANGE_PASSWORD);
		}

		switchTab(tab: MY_ACCOUNT_TAB) {
			if (tab === this.activeMyAccountTab) return;
			mutationsWrapper.setActiveMyAccountTab(this.$store, tab);
		}

		mounted() {
			this.$nextTick(() => {
				if (this.$route.query[QUERY_SETTINGS] === QUERY_SETTINGS_EMAIL_NOTIFICATIONS) {
					mutationsWrapper.setActiveMyAccountTab(this.$store, MY_ACCOUNT_TAB.EMAIL_NOTIFICATIONS);
					const newQuery = { ...this.$route.query };
					delete newQuery[QUERY_SETTINGS];

					this.$router.replace({
						...this.$route,
						query: newQuery,
					});
				}
			});
		}
	}
