
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { AVATAR_SIZE_LIMIT, AVATAR_MIN_WIDTH } from '@pixcap/ui-core/constants/user.constants';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
	import { PersonalData } from '@pixcap/ui-core/models/store/user.interface';
	import { mutationsWrapper } from '@pixcap/ui-core/store/user';

	import Avatar from '@pixcap/ui-core/components/common/Avatar.vue';
	// icons list
	import IconCamera from '@pixcap/ui-library/components/Icons/IconCamera.vue';
	import { $notify } from '@pixcap/ui-core/helpers/notification';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';

	@Component({
		name: 'ChangeAvatar',
		components: {
			Button,
			BodyText,
			IconCamera,
			Avatar,
		},
	})
	export default class ChangeAvatar extends Vue {
		@Prop() readonly user: PersonalData['user'];

		previewAvatarFile: any = '';
		AVATAR_SIZE_LIMIT = AVATAR_SIZE_LIMIT;
		AVATAR_MIN_WIDTH = AVATAR_MIN_WIDTH;

		$refs: {
			avatarInput: HTMLInputElement;
			preview: HTMLCanvasElement;
		};

		handleChangeAvatar() {
			this.$refs.avatarInput.click();
		}

		previewAvatar(e) {
			const files = e.target.files;
			if (files.length) {
				const file = files[0];
				this.$refs.avatarInput.value = '';
				// check image size limit
				if (file.size / 1000 / 1024 > AVATAR_SIZE_LIMIT) {
					$notify({
						type: NotificationType.ERROR,
						title: 'Image cannot be larger than 5MB',
					});
					return;
				}
				mutationsWrapper.toggleCroppAvatar(this.$store, file);
			}
		}
	}
