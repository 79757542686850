
	import { Component, Mixins } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import UpgradeSubscriptionMixin from '@pixcap/ui-core/components/payment/UpgradeSubscriptionMixin';
	import { FREE_TRIAL_PLAN_INFO, USAGE_LIMIT } from '@pixcap/ui-core/constants/subscription.constants';
	import { actionsWrapper as UserActions, mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import {
		Getters as UserGetters,
		GetterTypes as UserGettersTypes,
		NAMESPACE as USER_NAMESPACE,
		PAYMENT_STATUS,
		SUBSCRIPTION,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import UpgradeButton from '@pixcap/ui-core/components/payment/UpgradeButton.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';

	/// icons list
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';
	import { formatTime } from '@pixcap/ui-core/utils/DatetimeUtils';

	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';

	@Component({
		name: 'Subscription',
		components: {
			IconCrown,
			BodyText,
			HeadingText,
			CaptionText,

			Button,
			UpgradeButton,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				user: (state: UserState) => state.personalData?.user,
			}),
			...mapGetters(USER_NAMESPACE, {
				isUnsubscribedUser: UserGettersTypes.IS_UNSUBSCRIBED_USER,
				isCanceledSubscription: UserGettersTypes.IS_CANCELED_SUBSCRIPTION,
				isFreeTrialActive: UserGettersTypes.IS_FREE_TRIAL_ACTIVE,
				getPricingForUser: UserGettersTypes.GET_PRICING_FOR_USER,
			}),
		},
	})
	export default class Subscription extends Mixins(UpgradeSubscriptionMixin) {
		user: UserState['personalData']['user'];
		isCanceledSubscription: ReturnType<UserGetters[UserGettersTypes.IS_CANCELED_SUBSCRIPTION]>;
		isFreeTrialActive: ReturnType<UserGetters[UserGettersTypes.IS_FREE_TRIAL_ACTIVE]>;

		isLoadingInvoices = false;
		SUBSCRIPTION = SUBSCRIPTION;

		get previousSubscriptionPrice() {
			if (this.subscriptionInfo.price) {
				if (this.isAnnuallyPlan) {
					const amount = this.sanitizePrice(Number(this.subscriptionInfo.price.replace(/[^0-9\.]+/g, '')) / 12);
					const currency = this.subscriptionInfo.price.match(/[^\d,]/g).join('');
					return `${currency}${amount}`;
				}
				return this.subscriptionInfo.price;
			} else return null;
		}

		get defaultDownloadLimit() {
			return this.user?.limits.exportsDefault.daily;
		}

		get hqImageLimit() {
			return this.user?.limits.exportsImage.monthly;
		}

		get hqVideoLimit() {
			return this.user?.limits.exportsVideo.monthly;
		}

		get aiUsageLimit() {
			return this.user?.limits.aiUses.monthly;
		}

		get openInBlankTab() {
			return ['CustomizerEditor', 'Editor'].includes(this.$route.name);
		}

		get subscription() {
			return this.subscriptionInfo.subscription;
		}

		get expireDate() {
			return formatTime(this.subscriptionInfo.currentPeriodEnd);
		}

		get upgradePlanTitle() {
			return this.$t ? this.$t('page.upgrade_plan.do_more.upgrade_now') : 'Upgrade now';
		}

		// regarding subscription
		get isFreePlan() {
			return this.subscription === SUBSCRIPTION.BASIC;
		}

		get isAnnuallyPlan() {
			return this.subscription === SUBSCRIPTION.ELITE_ANNUALLY || this.subscription === SUBSCRIPTION.PRO_ANNUALLY;
		}

		get plan() {
			if (this.paymentStatus === PAYMENT_STATUS.FREE_TRIAL) {
				return FREE_TRIAL_PLAN_INFO;
			}
			const PRICING = this.getPricingForUser;
			if (this.subscription === SUBSCRIPTION.PRO_QUARTERLY) {
				return {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.pro_quarterly_plan') : 'Pro Quarterly Plan',
					price: this.previousSubscriptionPrice,
					period: 'For 3 months',
				};
			}
			if (this.subscription === SUBSCRIPTION.ELITE_QUARTERLY) {
				return {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.elite_quarterly_plan') : 'Elite Quarterly Plan',
					price: this.previousSubscriptionPrice,
					period: 'For 3 months',
				};
			}
			const PLAN_INFO = {
				[SUBSCRIPTION.BASIC]: {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.free_plan') : 'FREE Plan.',
					price: 'FREE',
					period: null,
				},
				[SUBSCRIPTION.PRO_ANNUALLY]: {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.pro_annual_plan') : 'Pro Annual Plan',
					price: PRICING.CURRENCY_PREFIX + this.sanitizePrice(PRICING.PRO_ANNUAL / 12),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.per_year') : 'Per year',
				},
				[SUBSCRIPTION.ELITE_ANNUALLY]: {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.elite_annual_plan') : 'Elite Annual Plan',
					price: PRICING.CURRENCY_PREFIX + this.sanitizePrice(PRICING.ELITE_ANNUAL / 12),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.per_year') : 'Per year',
				},
			};
			if (PRICING.PRO_MONTH) {
				PLAN_INFO[SUBSCRIPTION.PRO_MONTHLY] = {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.pro_monthly_plan') : 'Pro Monthly Plan',
					price: PRICING.CURRENCY_PREFIX + this.sanitizePrice(PRICING.PRO_MONTH),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.per_month') : 'Per month',
				};
			}
			if (PRICING.PRO_QUARTER) {
				PLAN_INFO[SUBSCRIPTION.PRO_QUARTERLY] = {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.pro_quarterly_plan') : 'Pro Quarterly Plan',
					price: PRICING.CURRENCY_PREFIX + this.sanitizePrice(PRICING.PRO_QUARTER),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.for_3_months') : 'For 3 months',
				};
			}
			if (PRICING.ELITE_MONTH) {
				PLAN_INFO[SUBSCRIPTION.ELITE_MONTHLY] = {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.elite_monthly_plan') : 'Elite Monthly Plan',
					price: PRICING.CURRENCY_PREFIX + this.sanitizePrice(PRICING.ELITE_MONTH),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.per_month') : 'Per month',
				};
			}
			if (PRICING.ELITE_QUARTER) {
				PLAN_INFO[SUBSCRIPTION.ELITE_QUARTERLY] = {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.elite_quarterly_plan') : 'Elite Quarterly Plan',
					price: PRICING.CURRENCY_PREFIX + this.sanitizePrice(PRICING.ELITE_QUARTER),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.for_3_months') : 'For 3 months',
				};
			}
			return PLAN_INFO[this.subscription || SUBSCRIPTION.BASIC];
		}

		get downloadsLimit() {
			return [
				{
					title: this.$t ? this.$t('page.user_settings.subscription.downloads_limit.default') : 'Default-quality image + video',
					limit: this.isUnsubscribedUser
						? USAGE_LIMIT.DEFAULT_DOWNLOAD.FREE
						: this.isProUser
						? USAGE_LIMIT.DEFAULT_DOWNLOAD.PRO
						: USAGE_LIMIT.DEFAULT_DOWNLOAD.ELITE,
					usage: this.isUnsubscribedUser ? this.defaultDownloadLimit : null,
					thumbnail: `${_PIXCAP_ENV.ROOT_URL}/cdn/app/download-queue/format-default.webp`,
					prefix: this.$t ? this.$t('page.user_settings.subscription.per_day') : 'per day',
					tag: null,
				},
				{
					title: this.$t ? this.$t('page.user_settings.subscription.downloads_limit.hq_image') : 'High-quality image',
					limit: this.isUnsubscribedUser
						? USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.FREE
						: this.isProUser
						? USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.PRO
						: USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.ELITE,
					usage: this.hqImageLimit,
					thumbnail: `${_PIXCAP_ENV.ROOT_URL}/cdn/app/download-queue/format-image.webp`,
					prefix: this.$t ? this.$t('page.user_settings.subscription.per_month') : 'per month',
					tag: 'HQ',
				},
				{
					title: this.$t ? this.$t('page.user_settings.subscription.downloads_limit.hq_video') : 'High-quality video',
					limit: this.isUnsubscribedUser
						? USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.FREE
						: this.isProUser
						? USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.PRO
						: USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.ELITE,
					usage: this.hqVideoLimit,
					hasTopUp: this.user?.limits.exportsVideo.additional > 0,
					thumbnail: `${_PIXCAP_ENV.ROOT_URL}/cdn/app/download-queue/format-video.webp`,
					prefix: this.$t ? this.$t('page.user_settings.subscription.per_month') : 'per month',
					tag: 'HQ',
				},
			];
		}

		get aiLimits() {
			return {
				title: this.$t ? this.$t('page.user_settings.subscription.ai_limits.ai_uses') : 'AI uses',
				limit: this.isUnsubscribedUser ? USAGE_LIMIT.AI_USAGE.FREE : this.isProUser ? USAGE_LIMIT.AI_USAGE.PRO : USAGE_LIMIT.AI_USAGE.ELITE,
				usage: this.aiUsageLimit,
				thumbnail: `${_PIXCAP_ENV.ROOT_URL}/cdn/app/icons/star-icon.png`,
				prefix: this.$t ? this.$t('page.user_settings.subscription.per_month') : 'per month',
			};
		}

		handleUpgradePlan(href) {
			if (this.openInBlankTab) window.open(href);
			else this.$router.push(href);
		}

		handlePendingOrFailedPayment() {
			if (this.subscriptionDueRenewal) {
				window.location.href = this.$router.resolve({ name: 'PricingPage' }).href;
			} else if (this.isSubscribedWithStripe) {
				this.openCustomerPortal();
			} else {
				if (
					this.subscription == SUBSCRIPTION.PRO_MONTHLY ||
					this.subscription == SUBSCRIPTION.PRO_QUARTERLY ||
					this.subscription == SUBSCRIPTION.PRO_ANNUALLY
				) {
					this.handleGetPro();
				} else if (
					this.subscription == SUBSCRIPTION.ELITE_MONTHLY ||
					this.subscription == SUBSCRIPTION.ELITE_QUARTERLY ||
					this.subscription == SUBSCRIPTION.ELITE_ANNUALLY
				) {
					this.handleGetElite();
				}
			}
		}

		clickCancel() {
			UserMutations.toggleCancelPlanModal(this.$store);
		}

		sanitizePrice(rawPrice: number) {
			if (rawPrice % 1 == 0) {
				return rawPrice.toLocaleString('en-US');
			} else {
				return Number(rawPrice.toFixed(2)).toLocaleString('en-US');
			}
		}

		async clickRenew() {
			this.isLoading = true;
			await UserActions.renewSubscription(this.$store);
			this.isLoading = false;
		}

		async openInvoicesPortalPortal() {
			this.isLoadingInvoices = true;
			const url = await UserActions.fetchInvoicesPortal(this.$store);
			this.isLoadingInvoices = false;
			if (url) {
				const invoicesWindow = window.open(url);
				if (!invoicesWindow || invoicesWindow.closed) window.location.href = url;
			}
		}
	}
