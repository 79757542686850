
	import { Vue, Component } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import { mutationsWrapper } from '@pixcap/ui-core/store/user';
	import { NAMESPACE as USER_NAMESPACE, UserState, MY_ACCOUNT_TAB } from '@pixcap/ui-core/models/store/user.interface';
	import { TEXT_TABS_SHOW } from '@pixcap/ui-core/constants/user.constants';
	import UserInfo from '@pixcap/ui-core/components/account/UserInfo.vue';
	import AccountNavLink from '@pixcap/ui-core/components/account/common/AccountNavLink.vue';
	import AccountSettingForm from '@pixcap/ui-core/components/account/form/AccountSettingForm.vue';
	import ChangePasswordForm from '@pixcap/ui-core/components/account/form/ChangePasswordForm.vue';
	import Subscription from '@pixcap/ui-core/components/account/Subscription.vue';
	/// icons list
	import IconArrowRight from '@pixcap/ui-library/components/Icons/IconArrowRight.vue';
	@Component({
		name: 'MyAccountMobile',
		components: {
			AccountNavLink,
			AccountSettingForm,
			ChangePasswordForm,
			Subscription,
			UserInfo,
			IconArrowRight,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
				activeMyAccountTab: (state: UserState) => state.activeMyAccountTab,
			}),
		},
	})
	export default class MyAccountMobile extends Vue {
		MY_ACCOUNT_TAB = MY_ACCOUNT_TAB;
		isDefaultView = true;

		personalData: UserState['personalData'];
		activeMyAccountTab: UserState['activeMyAccountTab'];

		get tabs() {
			const tabs = [MY_ACCOUNT_TAB.ACCOUNT_SETTINGS, MY_ACCOUNT_TAB.SUBSCRIPTION];
			if (!this.personalData?.user.isFederated) tabs.push(MY_ACCOUNT_TAB.CHANGE_PASSWORD);
			return tabs;
		}
		get headerTitle() {
			if (this.isDefaultView) return 'SETTINGS';
			else return TEXT_TABS_SHOW[this.activeMyAccountTab];
		}
		switchTab(tab: MY_ACCOUNT_TAB) {
			mutationsWrapper.setActiveMyAccountTab(this.$store, tab);
			this.isDefaultView = false;
		}
		handleClose() {
			if (this.isDefaultView) this.$emit('closeModal');
			else this.isDefaultView = true;
		}

		handleLogOut() {
			AuthActions.logout(this.$store, { sessionChange: false });
			this.$emit('closeModal');
		}
	}
