
	import { Component, Vue } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { NAMESPACE as USER_NAMESPACE, UserState, GetterTypes as UserGetterTypes } from '@pixcap/ui-core/models/store/user.interface';
	import MyAccount from '@pixcap/ui-core/components/account/MyAccount.vue';
	import MyAccountMobile from '@pixcap/ui-core/components/account/MyAccountMobile.vue';
	import UserAvatarCropper from '@pixcap/ui-core/components/account/UserAvatarCropper.vue';
	import { CONTEXT_PROVIDER_TYPES } from '@pixcap/ui-core/models/widgets/contextProvider.interface';

	@Component({
		name: 'MyAccountModal',
		components: {
			MyAccount,
			MyAccountMobile,
			UserAvatarCropper,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				CroppPictureModal: (state: UserState) => state.CroppPictureModal,
			}),
			...mapGetters(USER_NAMESPACE, {
				showMyAccountModal: UserGetterTypes.SHOW_MY_ACCOUNT_MODAL,
			}),
		},
	})
	export default class MyAccountModal extends Vue {
		CroppPictureModal: UserState['CroppPictureModal'];

		showMyAccountModal: boolean;

		_handleScreenSize;
		isMobileView: boolean = false;

		checkMobileView() {
			const windowWidth = window.innerWidth;
			const isMobileView = windowWidth <= 743;
			this.isMobileView = isMobileView;
		}

		toggleAccountSettingModal() {
			UserMutations.toggleAccountSettingModal(this.$store);
		}

		mounted() {
			this._handleScreenSize = this.checkMobileView.bind(this);
			window.addEventListener('resize', this._handleScreenSize);
			this.checkMobileView();
		}

		beforeDestroy() {
			const { contextProvider } = this.$pixcap.$widgets;
			contextProvider.resetProviderInjections(CONTEXT_PROVIDER_TYPES.DASHBOARD_LAYOUT);
			window.removeEventListener('resize', this._handleScreenSize);
		}
	}
